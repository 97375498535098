const toolbarmenu = {
    home: "Accueil",
    menu: "Carte",
    traiteur: "Traiteur",
    contact: "Contactez-nous",
    map: "Où sommes-nous ?"
}

const traiteur = {
    headtext: "<p><b>LE BAR A COUSCOUS</b></p><p>Le Bar à Couscous est un restaurant où une ambiance conviviale et familiale règne.<br />Nos couscous et tajines sont travaillés comme jadis, avec un savoir-faire traditionnel.<br />Passez un excellent moment en famille, entre amis et collègues autour de notre cuisine aux saveurs inédites.</p>",
    maptext: "<p>Situé à Pérenchies, nous desservons plusieurs communes, telles Lompret, Presmesques, Verlinghem, Frelinghien, Quesnoy/Deule, Capinghem, Lomme, Lambersart, Englos, Sequedin, les Weppes ...</p>",
    chieftext: "<p><b>NOTRE CHEF</b></p><p>Ex-lauréat de l'école hôtelière et gastonomique au Maroc dans la ville impériale de Fès, c'est un artiste culinaire.<br />Fort de sa trentaine d'années d'expérience, il vous fera voyager dans le monde de la gastronomie orientale.</p>",
    traiteurtext: "<p><b>NOTRE SERVICE TRAITEUR</b></p><p>Nous vous proposons un service traiteur personnalisé pour satisfaire toutes vos envies !<br />Séminaires, repas d'entreprises et de fin d'année, heureux évènements, mariages, naissances etc.<br />En fonction de votre demande, nous pouvons créer une ambiance unique : mise en place d'un groupe folklore, danseuses du ventre, musiciens etc.<br />Au besoin, nous vous fournissons également les couverts.<br />N'hésitez pas à nous contacter pour toute demande d'informations et de devis !</p>",
    coltitle: "<b>NOTRE DEVISE</b>",
    col1: "<p><b style='color:#445f7d'>FRAIS & LOCAL</b></p><p style='font-size:small;margin-top:210px'>Nous privilégions les produits frais locaux et de saisons. Pour cela, nous travaillons aux côtés d'une famille d'agriculteurs.",
    col2: "<p><b style='color:#445f7d'>FAIT-MAISON</b></p><p style='font-size:small;margin-top:210px'>Toutes nos recettes sont faites-maison, cuisinées avec passion par notre chef cuisinier.",
    col3: "<p><b style='color:#445f7d'>ECO-RESPONSABILITE</b></p><p style='font-size:small;margin-top:210px'>En gage de notre responsabilité pour la planète, l'ensemble de nos emballages sont recyclables."
}

const carte = {
    title: "MENU",
    entree: "Les Entrées",
    plat: "Plats",
    plat_box: "Couscous Box",
    plat_classique: "Les Couscous",
    plat_tajines: "Les Tajines",
    plat_supplements: "Les Suppléments",
    plat_boissons: "Les Boissons",
    plat_desserts: "Les Desserts"
}

const cards = {
    head1: "Qualité",
    content1: "Découvrez notre couscous de qualité supérieur, fait chaque jour pour vous.",
    head2: "Efficacité",
    content2: "Parce que la qualité ne doit pas au détriment de votre temps, nous nous engageons à vous servir au plus vite.",
    head3: "Sourire",
    content3: "Nous vous accueillons avec le sourire et la bonne humeur.",

}

const headlines = {
    head1: "Qualité",
    content1: "Découvrez nos spécialités culinaires préparées au quotidien à l'aide de produits locaux.",
    head2: "Efficacité",
    content2: "Nos plats vous seront servis rapidement pour que vous puissiez prendre le temps de les déguster.",
    head3: "Sourire",
    content3: "Partagez entre amis ou en famille des plats ensoleillés qui vous feront voyager.",
    head4: "Ensemble",
    content4: "\"Le Bar à Couscous\" célèbre chaque jour, avec vous, le mélange des cultures.",
    head5: "Cadre",
    content5: "Relaxez vous au son de notre fontaine dans un décor moderne avec une touche orientale."
}

export { toolbarmenu, carte, cards, headlines, traiteur };