import React from 'react';
import {Container,Row,Col} from 'reactstrap';
import Spec from './spec/spec';
import { headlines} from '../../../lng/fr';
import clock from '../../../Assests/icons/icon_specs/clock.png';
import chef from '../../../Assests/icons/icon_specs/hat.png';
import leaf from '../../../Assests/icons/icon_specs/leaf.png';
import basket from '../../../Assests/icons/icon_specs/basket.png';
import cadre from '../../../Assests/icons/icon_specs/cadre.png';

const specs=()=>{
    return(
        <Container>
            <Row>
                <Col>
                    <Spec src={basket} head={headlines.head1} content={headlines.content1} />
                </Col>
                <Col>
                    <Spec src={clock}  head={headlines.head2} content={headlines.content2} />
                </Col>
                <Col>
                 <Spec src={leaf} head={headlines.head3} content={headlines.content3} />
                </Col>
                <Col>
                 <Spec src={chef}  head={headlines.head4} content={headlines.content4} />
                </Col>
                <Col>
                 <Spec src={cadre}  head={headlines.head5} content={headlines.content5} />
                </Col>
            </Row>
        </Container>
    );
}

export default specs;